import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CssBaseline,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getLPTheme from "../../getLPTheme"; 

const HelpAccordion = ({ icon, title, children }) => (
  <Accordion sx={{ border: "none" }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{ backgroundColor: "transparent" }}
    >
      {icon}
      <Typography variant="h6">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: "0px" }}>
      <Typography variant="body1" sx={{ padding: "16px" }}>
        {children}
      </Typography>
    </AccordionDetails>
  </Accordion>
);

const HelpPage = () => {
  const [mode, setMode] = useState(() => {
    return localStorage.getItem("theme") || "dark";
  });

  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);


  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <div  style={{
          zIndex: 2,
          display: "flex",
          justifyContent: "center", 
          alignItems: "center", 
          height: "100vh", 
          padding: "10px",
        }}>
        <Box
          sx={{
            padding: "30px",
            maxWidth: "800px",
            margin : "auto",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "10px",
            backgroundColor: LPtheme.palette.background.paper, // Apply theme background
            color: LPtheme.palette.text.primary, // Apply theme text color
            boxShadow:
              LPtheme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              fontFamily: "Audiowide, sans-serif",
              boxShadow:
                LPtheme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            }}
          >
            Quick Help
          </Typography>
          <h1>Coming Soon!</h1>

          {/* <HelpAccordion
            icon={<HomeIcon sx={{ marginRight: "10px" }} />}
            title="Create a Project"
          >
            Click the <strong>+</strong> next to <strong>Designer Space</strong> to create a new project.
          </HelpAccordion>

          <HelpAccordion
            icon={<MeetingRoomIcon sx={{ marginRight: "10px" }} />}
            title="Add/Delete Rooms"
          >
            Click <strong>+</strong> to add rooms, or the <DeleteIcon fontSize="small" sx={{ verticalAlign: 'middle', marginLeft: '5px', marginRight: '5px' }} /> to delete them.
          </HelpAccordion>

          <HelpAccordion
            icon={<InsertDriveFileIcon sx={{ marginRight: "10px" }} />}
            title="Upload Views"
          >
            Upload images as views by clicking <strong>+</strong> in a room.
          </HelpAccordion>

          <HelpAccordion
            icon={<ShareIcon sx={{ marginRight: "10px" }} />}
            title="Share Projects"
          >
            Share a project using the <ShareIcon fontSize="small" sx={{ verticalAlign: 'middle', marginLeft: '5px', marginRight: '5px' }}/> icon next to a project name.
          </HelpAccordion>

          <HelpAccordion
            icon={<DeleteIcon sx={{ marginRight: "10px" }} />}
            title="Delete Projects"
          >
            Click the <DeleteIcon fontSize="small" sx={{ verticalAlign: 'middle', marginLeft: '5px', marginRight: '5px' }}/> next to the project to delete it.
          </HelpAccordion> */}
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default HelpPage;
