import React, { useEffect, useState } from "react";
import cursor from "../icons/cursor_cardboard.png";

const KeyListener = () => {
  // const [gamepad, setGamepad] = useState(null);
  // const [buttonStates, setButtonStates] = useState([]);

  // const getGamePad = () => {
  //   const gamepads = navigator.getGamepads();
  //   if (gamepads[0]) setGamepad(gamepads[0]);
  // };

  // const pollGamepad = () => {
  //   if (gamepad) {
  //     const buttons = gamepad.buttons.map((button) => button.pressed);
  //     setButtonStates(buttons);
  //     for (let i = 0; i < buttons.length; i++) {
  //       if (buttons[i]) {
  //         console.log(`Button ${i} pressed`);
  //       }
  //     }
  //     const axes = gamepad.axes;
  //     for (let i = 0; i < axes.length; i++) {
  //       if (axes[i] > 0.5 || axes[i] < -0.5) {
  //         console.log(`Axis ${i} moved`);
  //       }
  //     }
  //   }

  //   requestAnimationFrame(pollGamepad); // Call pollGamepad on the next frame
  // };

  // useEffect(() => {
  //   window.addEventListener("gamepadconnected", getGamePad);
  //   window.addEventListener("gamepaddisconnected", getGamePad);

  //   return () => {
  //     window.removeEventListener("gamepadconnected", getGamePad);
  //     window.removeEventListener("gamepaddisconnected", getGamePad);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (gamepad) {
  //     console.log(gamepad);
  //     const buttons = gamepad.buttons;
  //     console.log(buttons);
  //     pollGamepad(); // Start polling for button presses
  //   }
  // }, [gamepad]);

  // async function connectToHID() {
  //   try {
  //     // Request a device with HID service
  //     const device = await navigator.bluetooth.requestDevice({
  //       acceptAllDevices: true,
  //       // filters: [{ services: ["00001812-0000-1000-8000-00805f9b34fb"] }], // HID service
  //     });

  //     // Connect to the GATT server
  //     const server = await device.gatt.connect();
  //     console.log("Connected to device:", device.name);

  //     // Get the HID service
  //     const service = await server.getPrimaryService(
  //       "00001812-0000-1000-8000-00805f9b34fb"
  //     );

  //     // Get the specific characteristic for input reports
  //     const characteristic = await service.getCharacteristic(
  //       "00002a38-0000-1000-8000-00805f9b34fb"
  //     ); // Report characteristic UUID

  //     // Listen for changes in the characteristic
  //     characteristic.addEventListener(
  //       "characteristicvaluechanged",
  //       handleCharacteristicValueChanged
  //     );
  //     await characteristic.startNotifications();

  //     console.log("Listening for HID events...");
  //   } catch (error) {
  //     console.error("Error connecting to Bluetooth device:", error);
  //   }
  // }

  // function handleCharacteristicValueChanged(event) {
  //   const value = event.target.value;

  //   // Process the HID report data
  //   const data = new Uint8Array(value.buffer);
  //   console.log("HID Data Received:", data);

  //   // Example: Interpret the data (customize based on your device)
  //   // Check data structure based on your HID device's report format
  //   const movementX = data[1]; // Replace with actual index based on your report format
  //   const movementY = data[2]; // Replace with actual index based on your report format

  //   console.log(`Movement - X: ${movementX}, Y: ${movementY}`);
  // }

  // useEffect(() => {
  //   //keyup event listener
  //   document.getElementsByTagName("a-scene")[0].addEventListener("keyup", (e) => {
  //     console.log("Key up event", e);
  //   });

  //   //keydown event listener
  //   document.getElementsByTagName("a-scene")[0].addEventListener("keydown", (e) => {
  //     console.log("Key down event", e);
  //   });

  //   return () => {
  //     document.getElementsByTagName("a-scene")[0].removeEventListener("keyup", (e) => {
  //       console.log("Key up event", e);
  //     });
  //     document.getElementsByTagName("a-scene")[0].removeEventListener("keydown", (e) => {
  //       console.log("Key down event", e);
  //     });
  //   }
  // }, []);

  //   if(!window.AFRAME.components['mystuff']) {
  //     window.AFRAME.registerComponent('mystuff', {
  //       init: function () {
  //           this.handlers = {
  //               buttondown: function (evt) {
  //                   console.log('button down event', evt);

  //               },
  //           }
  //       },

  //       play: function () {
  //           const controlsEl = document.querySelector('[button-controls]');
  //           controlsEl.addEventListener('buttondown', this.handlers.buttondown);
  //       },

  //       pause: function () {
  //           const controlsEl = document.querySelector('[button-controls]');
  //           controlsEl.removeEventListener('buttondown', this.handlers.buttondown);
  //       }
  //   });
  // }
  // useEffect(() => {
  //   //mouse move event listener
  //   document.addEventListener("mousemove", (e) => {
  //     console.log("Mouse move event", e);
  //   });
  // }, []);

  //listen for mouse intersection
    // if (!window.AFRAME.components["raycastable"]) {
    //   window.AFRAME.registerComponent("raycastable", {
    //     init: function () {
    //       this.el.addEventListener("raycaster-intersected", function (evt) {
    //         console.log("raycaster intersected", evt);
    //       });
    //     },
    //   });
    // }



  if (!window.AFRAME.components["scene"]) {
    window.AFRAME.registerComponent("scene", {
      init: function () {
        const sceneEl = this.el; // Reference to the entire a-scene

        sceneEl.addEventListener("mousemove", (e) => {
          try {

            //grab mouse pointer 
            document.querySelector(".a-canvas").style.cursor="none";
            document.body.style.cursor = "none";
            
            // Step 1: Normalize device coordinates (NDC)
            const ndcX = (e.clientX / window.innerWidth) * 2 - 1;
            const ndcY = -(e.clientY / window.innerHeight) * 2 + 1;

            // Step 2: Get the camera and cursor elements
            const cameraEl = document.querySelector("a-camera");
            const cursor = document.getElementById("cursor");

            if (!cameraEl || !cursor) {
              console.error("Camera or cursor not found in the scene.");
              return;
            }

            // Step 3: Set up the raycaster
            const raycaster = new window.THREE.Raycaster();
            raycaster.setFromCamera(
              { x: ndcX, y: ndcY },
              cameraEl.getObject3D("camera")
            );

            // Step 4: Perform raycasting on all objects in the scene
            const intersects = raycaster.intersectObjects(
              sceneEl.object3D.children,
              true
            );
            if (intersects.length > 0) {
              // Log the intersection point
              // console.log("Intersected point:", intersects[0].point);
              //log element intersected
              // console.log("Intersected element:", intersects[0].object.el);

              // Update the cursor position with the intersection point
              cursor.setAttribute("position", {
                x: intersects[0].point.x,
                y: intersects[0].point.y,
                z: intersects[0].point.z,
              });
            }
          } catch (error) {
            console.error("Error in mousemove event", error);
          }
        });

      },
    });
  }

  useEffect(() => {
    document.querySelector("a-scene").addEventListener("loaded", (e) => {
      console.log("hi");
      document.querySelector(".a-canvas").style.cursor = "none";
      document.body.style.cursor = "none";
    });
  }, []);

  return (
    <div>
      <h1>KeyListener</h1>
      <a-scene scene>
        <a-sky color="#ECECEC"></a-sky>
        <a-camera camera></a-camera>
        <a-image
          position="0 1 -5"
          id="cursor"
          width="0.25"
          height="0.25"
          cursor="rayOrigin: entity"
          src={cursor}
        />
        <a-sphere
          position="0 1.25 -5"
          radius="1.25"
          raycastable
          color="#EF2D5E"
        ></a-sphere>
        <a-cylinder
          position="1 0.75 -3"
          raycastable
          radius="0.5"
          height="1.5"
          color="#FFC65D"
        ></a-cylinder>
        <a-sphere
          position="0 0 0"
          radius={"3"}
          raycastable
          color="#7BC8A4"
        ></a-sphere>
      </a-scene>
    </div>
  );
};

export default KeyListener;
