import React, { useEffect, useRef, useState, useContext } from "react";
import "aframe";
import {
  Popper,
  TextField,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { auth } from "../authentication/Firebase";
import { AppContext } from "../../AppContext";
// import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { URL } from "../constants";
import commonFunctions from "../CommonComponents/commonFunctions";
import interiaLogo from "../../icons/interia_logo.png";
import spiral_rotate from "../../icons/spiral_rotate.svg";
const imageExtensionList = [
  "mp4",
  "mov",
  "mkv",
  "ase",
  "art",
  "bmp",
  "blp",
  "cd5",
  "cit",
  "cpt",
  "cr2",
  "cut",
  "dds",
  "dib",
  "djvu",
  "egt",
  "exif",
  "gif",
  "gpl",
  "grf",
  "icns",
  "ico",
  "iff",
  "jng",
  "jpeg",
  "jpg",
  "jfif",
  "jp2",
  "jps",
  "lbm",
  "max",
  "miff",
  "mng",
  "msp",
  "nef",
  "nitf",
  "ota",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pcx",
  "pdn",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "pct",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "pdd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "raw",
  "rle",
  "sct",
  "sgi",
  "rgb",
  "int",
  "bw",
  "tga",
  "tiff",
  "tif",
  "vtf",
  "xbm",
  "xcf",
  "xpm",
  "3dv",
  "amf",
  "ai",
  "awg",
  "cgm",
  "cdr",
  "cmx",
  "dxf",
  "e2d",
  "egt",
  "eps",
  "fs",
  "gbr",
  "odg",
  "svg",
  "stl",
  "vrml",
  "x3d",
  "sxd",
  "v2d",
  "vnd",
  "wmf",
  "emf",
  "art",
  "xar",
  "png",
  "webp",
  "jxr",
  "hdp",
  "wdp",
  "cur",
  "ecw",
  "iff",
  "lbm",
  "liff",
  "nrrd",
  "pam",
  "pcx",
  "pgf",
  "sgi",
  "rgb",
  "rgba",
  "bw",
  "int",
  "inta",
  "sid",
  "ras",
  "sun",
  "tga",
  "heic",
  "heif",
];
var clickX = 0,
  clickY = 0;
const ImageViewer = () => {
  const cameraRef = useRef(null);
  const cursorRef = useRef(null);
  const theme = useTheme();
  // const theme = useTheme();
  const [imageWidth, setImageWidth] = useState(null);
  const {
    inHome,
    setInHome,
    selectedRef,
    setOpen,
    setMessage,
    handleSnackClick,
    edit,
    setEdit,
    data,
    setData,
    currentSphereNumber,
    setCurrentSphereNumber,
    metadataOpen,
    setMetadataOpen,
    keyValues,
    setKeyValues,
    metadataAnchorEl,
    setMetadataAnchorEl,
    setMetaDataRef,
    metadataPlacement,
    setMetadataPlacement,
    setConfirmDialogText,
    useConfirmDialog,
    metadataRef,
    sceneRef,
    skyRef,
    isLoading,
    setIsLoading,
  } = useContext(AppContext);

  const { confirm, dialog } = useConfirmDialog();
  const [name, setName] = useState("");

  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault();
    };

    if (cameraRef.current) {
      cameraRef.current.addEventListener("click", handleClick);
    }

    return () => {
      if (cameraRef.current) {
        // eslint-disable-next-line
        cameraRef.current.removeEventListener("click", handleClick);
      }
    };
  }, [imageWidth]);

  useEffect(() => {
    if (edit) {
      if (skyRef.current) {
        skyRef.current.addEventListener("mouseup", handleSphere);
      }
    }
    return () => {
      if (skyRef.current) {
        skyRef.current.removeEventListener("mouseup", handleSphere);
      }
    };
  }, [edit]);

  useEffect(() => {
    if (inHome === true) {
      //save metadata
      if(edit){
        handleSave();
      }
      //chnage image to interia lobby
      var newSky = document.createElement("a-sky");
      // newSky.setAttribute("src", interia_lobby);

      if (theme.palette.mode == "light") {
        newSky.setAttribute("color", "#696969");
      } else if (theme.palette.mode == "dark") {
        newSky.setAttribute("color", "#000000");
      }
      newSky.setAttribute("rotation", "0 -90 0");
      newSky.setAttribute("class", "clickable");
      if (skyRef.current) {
        skyRef.current.parentNode.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;
      }

      //remove all spheres
      Array.prototype.slice
        .call(document.querySelectorAll("[normal-to-camera]"))
        .forEach(function (item) {
          item.remove();
        });
      //clear metadata,roomPath,rooms
      setCurrentSphereNumber("0");
      setEdit(false);

    }
  }, [inHome]);

  useEffect(() => {
    var metadata = [];
    if (currentSphereNumber && data[currentSphereNumber]) {
      const sphereData = data[currentSphereNumber];
      setName(sphereData.name || ""); // Set name based on the current sphere's data

      for (const key in data[currentSphereNumber]) {
        if (key === "intersection" || key === "name") continue;
        metadata.push({
          key: key,
          value: data[currentSphereNumber][key],
        });
      }
    }
    if (metadata.length === 0) {
      metadata.push({ key: "", value: "" });
    }
    // var metadata = [];
    // for (const key in data[currentSphereNumber]) {
    //   if (key === "intersection") continue;
    //   metadata.push({
    //     key: key,
    //     value: data[currentSphereNumber][key],
    //   });
    // }

    //change other sphere colors to black
    Array.prototype.slice
      .call(document.getElementsByTagName("a-circle"))
      .forEach(function (item) {
        item.setAttribute("color", "black");
      });
    //change color of sphere
    var sphere = document.querySelector(
      'a-circle[number="' + currentSphereNumber + '"]'
    );
    if (sphere) {
      sphere.setAttribute("color", "blue");
    }
    setKeyValues(metadata);
  }, [currentSphereNumber, data]);

  if (!window.AFRAME.components["spheres"]) {
    window.AFRAME.registerComponent("spheres", {
      init: function () {
        this.el.addEventListener("mouseup", (event) => {
          var eventType = event.detail.mouseEvent.button;
          if (eventType === 0) {
            var canvasEl = this.el.sceneEl.canvas;
            var rect = canvasEl.getBoundingClientRect();
            clickX = event.detail.mouseEvent.clientX - rect.left;
            clickY = event.detail.mouseEvent.clientY - rect.top;
            // var camRotation = document
            //   .getElementsByTagName("a-camera")[0]
            //   .getAttribute("rotation");
            setCurrentSphereNumber(this.el.getAttribute("number"));
            setMetadataOpen(true);
            const metadataAnchor = document.getElementById("metadata_anchor");
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const metadataAnchorWidth = metadataAnchor.offsetWidth;
            const metadataAnchorHeight = metadataAnchor.offsetHeight;
            document.getElementById("metadata_anchor").style.position =
              "absolute";
            document.getElementById("metadata_anchor").style.left =
              clickX + "px";
            document.getElementById("metadata_anchor").style.top =
              clickY + "px";
            //find click position such that by comparing width of popper and height of popper
            if (clickX + metadataAnchorWidth > viewportWidth) {
              // Adjust position to the left of the click point
              setMetadataPlacement("left");
            } else {
              setMetadataPlacement("right");
            }

            // Check if the element would overflow the bottom edge of the viewport
            if (clickY + metadataAnchorHeight > viewportHeight) {
              // Adjust position to the top of the click point
              setMetadataPlacement("top");
            } else {
              setMetadataPlacement("bottom");
            }
            setMetadataAnchorEl(document.getElementById("metadata_anchor"));
          }
        });
      },
    });
  }

  const handleClose = () => {
    handleSave();
    setCurrentSphereNumber("0");
    setMetadataOpen(false);
  };

  const handleSave = () => {
    // Create new key-value pairs object
    const newKeyValuePairs = {};
    keyValues.forEach((field) => {
      newKeyValuePairs[field.key] = field.value;
    });

    // Ensure newData is a deep copy if necessary
    const newData = { ...data };
    const sphereData = newData[currentSphereNumber];

    if (!sphereData || currentSphereNumber === "0") {
      console.error("Invalid sphere number:", currentSphereNumber);
      return;
    }

    const { intersection } = sphereData;

    // Update sphere data with new key-value pairs
    newData[currentSphereNumber] = {
      intersection: intersection,
      name,
      ...newKeyValuePairs,
    };

    // Create metadata array excluding the intersection
    const metadata = Object.keys(newData[currentSphereNumber])
      .filter((key) => key !== "intersection" && key !== "name")
      .map((key) => ({
        key: key,
        value: newData[currentSphereNumber][key],
      }));

    // Update state
    setKeyValues(metadata);
    setData(newData); // Update the state with the new data
    setCurrentSphereNumber(""); // Set to empty string to indicate no current sphere selected
    setMetadataOpen(false);
  };

  const handleAddField = () => {
    setKeyValues([...keyValues, { key: "", value: "" }]);
  };

  const handleDeleteField = (index) => {
    setKeyValues(keyValues.filter((_, i) => i !== index));
  };

  const handleSphere = (evt) => {
    if (edit && metadataRef != null) {
      const eventType = evt.detail.mouseEvent.button;

      if (eventType === 2) {
        // Right-click event
        const spheres = document.getElementsByTagName("a-circle");
        const usedNumbers = new Set();

        // Collect all used sphere numbers, skipping 0
        for (let i = 0; i < spheres.length; i++) {
          const num = parseInt(spheres[i].getAttribute("number"), 10);
          if (!isNaN(num) && num !== 0) {
            usedNumbers.add(num);
          }
        }

        // Find the next available sphere number, skipping 0
        let num = 1;
        while (usedNumbers.has(num)) {
          num++;
        }

        const intersection = evt.detail.intersection;
        const camera = document.querySelector("[camera]");
        const cameraPosition = new window.THREE.Vector3();
        camera.object3D.getWorldPosition(cameraPosition);
        if (intersection && intersection.point) {
          const intersectionPoint = new window.THREE.Vector3(
            intersection.point.x,
            intersection.point.y,
            intersection.point.z
          );
          // Calculate the direction from the intersection point to the camera
          const directionVector = new window.THREE.Vector3()
            .subVectors(cameraPosition, intersectionPoint)
            .normalize();

          // Move the intersection point 10 units towards the camera
          const offsetDistance = 10;
          intersectionPoint.add(directionVector.multiplyScalar(offsetDistance));
          var jsonData = {
            [num]: {
              intersection: intersectionPoint,
            },
          };
          commonFunctions.createSpheres(jsonData);

          if (intersection) {
            // Update the metadata with the new sphere's position
            setData((prevData) => {
              const newData = { ...prevData };
              const meta = {
                intersection: {
                  x: intersectionPoint.x,
                  y: intersectionPoint.y,
                  z: intersectionPoint.z,
                },
              };
              newData[num] = meta;
              return newData;
            });
          }
        }
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // skyRef.current.setAttribute("src", interia_lobby);
    if (theme.palette.mode == "light") {
      skyRef.current.setAttribute("color", "#696969");
    } else if (theme.palette.mode == "dark") {
      skyRef.current.setAttribute("color", "#000000");
    }
  }, []);

  const handleDrop = async (event) => {
    if (event) event.preventDefault();
    var viewID = event.dataTransfer.getData("viewID");
    if (viewID === "") return;
    if (edit) {
      setConfirmDialogText(
        "Unsaved chnages are lost.Are you sure you want to change the image?"
      );
      const result = await confirm();
      if (!result) {
        return;
      }
    }
    var selectedRef = URL.baseURL + "/" + auth.currentUser.email + "/" + viewID;
    commonFunctions.loadImage(
      selectedRef,
      setConfirmDialogText,
      confirm,
      setEdit,
      setMetadataOpen,
      setMetaDataRef,
      setData,
      setMessage,
      handleSnackClick,
      setOpen,
      sceneRef,
      skyRef,
      edit,
      setIsLoading,
      setInHome
    );
  };

  const handleDeleteSphere = () => {
    var newData = data;
    delete newData[currentSphereNumber];
    setData(newData);
    var sphere = document.querySelector(
      'a-plane[number="' + currentSphereNumber + '"]'
    );
    sphere.parentNode.removeChild(sphere);

    setCurrentSphereNumber("0");
    setMetadataOpen(false);
  };

  return (
    <div onDragOver={handleDragOver} onDrop={handleDrop}>
      <div>
        <div id="metadata_anchor">Ref</div>
        <Popper
          id="metadata_form"
          sx={{ zIndex: 1000 }}
          open={metadataOpen}
          anchorEl={metadataAnchorEl}
          placement={metadataPlacement}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Box
              sx={{
                p: 1,
                borderRadius: 2,
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                bgcolor: "background.paper",
                opacity: 0.9,
              }}
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Name :</span>
                <TextField
                  type="text"
                  variant="standard"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    marginBottom: "10px",
                    marginLeft: "30px",
                    width: "185px",
                    // Space between Name input and Value label
                    "& .MuiInputBase-input": {
                      fontWeight: "bold", // Make input text bold
                    },
                  }}
                />
              </div>

              {keyValues.map((field, index) => (
                <div
                  key={index}
                  sx={{ marginBottom: "10px", display: "flex", gap: 1 }}
                >
                  {/* Input Field for Name */}
                  <TextField
                    type="text"
                    // label="price"
                    variant="standard"
                    size="small"
                    value={field.key}
                    onChange={(e) =>
                      setKeyValues(
                        keyValues.map((item, i) =>
                          i === index ? { ...item, key: e.target.value } : item
                        )
                      )
                    }
                    sx={{
                      width: "120px",
                      height: "30px",
                      marginRight: "20px",
                      "& .MuiInputLabel-root": {
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        color: "#A6A6A6",
                      },
                    }}
                  />
                  <TextField
                    type="text"
                    // label="XXXXX"
                    size="large"
                    value={field.value}
                    variant="standard"
                    onChange={(e) =>
                      setKeyValues(
                        keyValues.map((item, i) =>
                          i === index
                            ? { ...item, value: e.target.value }
                            : item
                        )
                      )
                    }
                    sx={{
                      width: "120px",
                      height: "30px",

                      "& .MuiInputLabel-root": {
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        color: "#A6A6A6",
                      },
                    }}
                  />
                  <IconButton onClick={() => handleDeleteField(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              ))}
              <Stack direction="row" justifyContent={"center"} spacing={2}>
                <Tooltip title={`Add new field`} arrow>
                  <ControlPointIcon
                    type="button"
                    variant="contained"
                    cursor="pointer"
                    onClick={handleAddField}
                  />
                </Tooltip>

                {/* <Button type="button" variant="contained" onClick={handleClose}>
                Close
              </Button> */}
                <Tooltip title={`Delete Sphere`} arrow>
                  <DeleteOutlineIcon
                    type="button"
                    variant="contained"
                    cursor="pointer"
                    onClick={handleDeleteSphere}
                  />
                </Tooltip>
              </Stack>
              {/* <Button type="submit">Save</Button> */}
            </Box>
          </ClickAwayListener>
        </Popper>
        {dialog}
      </div>
      <a-scene
        ref={sceneRef}
        link-controls
        onContextMenu={(e) => e.preventDefault()}
        renderer="antialias: true; logarithmicDepthBuffer: true; pixelRatio: window.devicePixelRatio"
      >
        <a-camera cam ref={cameraRef} wasd-controls="acceleration: 0">
          <a-entity
            ref={cursorRef}
            cursor="rayOrigin:mouse"
            raycaster="objects: .clickable; far: 1000;"
          ></a-entity>

          {!isLoading && (!skyRef.current || !skyRef.current.getAttribute("src")) ? (
            <a-image src={interiaLogo} position="0 0 -5"></a-image>
          ) : null}

  <a-image
  src={spiral_rotate}
  width="5"
    height="5"
    position={isLoading ? "0 -2 -20" : "1000 1000 10000"}
    animation="property: rotation;from: 0 0 0;to: 0 0 359; loop: true; dur: 1500"
  />

  
        </a-camera>
        <a-sky
          ref={skyRef}
          class="clickable"
          onContextMenu={handleSphere}
          // src={interia_lobby}
          color="#696969"
          rotation="0 -90 0"
        ></a-sky>
        <style>
          {`
  .a-enter-vr-button {
   display: none;
  }
`}
        </style>
      </a-scene>
    </div>
  );
};

export default ImageViewer;
