import React from "react";
import MenuItem from "./MenuItem";
//import all images from public folder in icons object
// import icons from "../../public/";

const designerIcon = "/icons/architect.png";
const projectIcon = "/icons/apartment-building.png";
const roomIcon = "/icons/room.png";
const viewsIcon = "/icons/searching.png";

const createMenu = ({
  isDesktop,
  rows = 1, // 2 rows
  itemGap = 4, // Gap between items
  columnGap = 4, // Gap between columns
  columnWidth = 4, // Width of each column
  menuButtonPositions = {
    // Custom midpoint positions for buttons
    previous: { x: -8, y: 12, z: -20 },
    next: { x: 8, y: 12, z: -20 },
    hide: { x: 0, y: -12, z: -20 },
  },
  maxItemsPerPage = 3, // 4 items per page
  displaySection, // Items to display
  currentSection, // Current section name
  currentPage, // Current page number
  setSelectedID, // Function to set selected ID
  menuPosition = "0 0 -10", // Default menu position
  textWidth = 20, // Text width for each item
  itemWidth = 7, // Default item width
  itemHeight = 7, // Default item height
  thumbnails,
  currentRoomName,
  currentProjectName,
  currentDesigner,
  currentView,
  currentViewName,
  theme,
}) => {
  const alphabetSet = "1234";
  const menuWidth =
    Math.ceil(maxItemsPerPage / rows) * (columnWidth + columnGap) - columnGap;
  const centerOffset = menuWidth / 2;
  
  console.log("Quest Menu theme is::::", theme.palette.mode);
const previousIcon = theme.palette.mode === 'dark' ?  "/icons/double_left_arrow_white.png":"/icons/double_left_arrow_black.png";
const backIcon = theme.palette.mode === 'dark' ? "/icons/left_arrow_white.png":"/icons/left_arrow_black.png";
const nextIcon = theme.palette.mode === 'dark' ? "/icons/right_arrow_white.png" : "/icons/right_arrow_black.png";


  // //sort displaySection alphabetically
  // if (displaySection) {
  //   displaySection = Object.keys(displaySection)
  //     .sort()
  //     .reduce((acc, key) => {
  //       acc[key] = displaySection[key];
  //       return acc;
  //     }, {});
  // }
  // console.log("displaySection", displaySection);
  

  const currentItems = displaySection
    ? Object.entries(displaySection).slice(
      currentPage * maxItemsPerPage,
      (currentPage + 1) * maxItemsPerPage
    )
    : [];
 
  const calculateWidth = (text) => itemWidth || text.length * 0.5;
  const calculateHeight = (text) => itemHeight || text.split("\n").length * 1.5;

  const headTitle = (() => {
    var title1 = ""
    var title2 = ""
    switch (currentSection) {
      case "Designer":
        title1 = ""
        title2 = "Designer"
        break;

      case "Project":
        title1 = ""
        title2 = currentDesigner
        break;

      case "Room":
        title1 = `${currentDesigner}`
        title2 = currentProjectName
        break;
      case "Views":
        title1 = currentViewName ? `${currentDesigner} : ${currentProjectName}: ${currentRoomName}` : `${currentDesigner} : ${currentProjectName}`
        title2 = currentViewName ? currentViewName : currentRoomName
        break;

      default:
        title1 = ""
        title2 = currentSection
    }
    return { title1, title2 };
  })();

  return (
    <a-entity position={menuPosition}>
      {displaySection && currentSection != null && (
        <>
          {/* <MenuItem
            text={headTitle.title1}
            position="0 13 -20"
            // width={25}
            // height={4.5}
            textWidth={textWidth + 3}
            setSelectedID={setSelectedID}
            selectable={false}
            primaryTextColor={              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)"}
            primaryPlaneColor={              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)"}
          /> */}
          <MenuItem
            text={(headTitle.title1 ? `${headTitle.title1}\n` : "") + headTitle.title2}
            position="0 12 -20"
            width={25}
            height={4}
            uniqueID={"Title"}
            textWidth={textWidth + 3}
            setSelectedID={setSelectedID}
            selectable={false}
            primaryTextColor={theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"}
            primaryPlaneColor={theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"}
          />
          {currentSection != "Designer" && (
            <MenuItem
              uniqueID={"Back"}
              // text={"<<"}
              position={`-11 12 -20`}
              width="2"
              height="2"
              setSelectedID={setSelectedID}
              selectable={true}
              primaryTextColor={theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)"}
              secondaryPlaneColor={theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)"}
              secondaryTextColor={theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)"}
              primaryPlaneColor={theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)"}
              textWidth={textWidth}
              className={"menu raycastable"}
              image={previousIcon}
            />
          )}
          <MenuItem
            uniqueID={"Previous"}
            position={`${menuButtonPositions.previous.x} ${menuButtonPositions.previous.y} ${menuButtonPositions.previous.z}`}
            // text={"<"}
            width="1.5"
            height="1.5"
            setSelectedID={setSelectedID}
            selectable={true}
            primaryTextColor={theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"}
            primaryPlaneColor={theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"}
            textWidth={textWidth}
            className={"menu raycastable"}
            secondaryPlaneColor={theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.87)"
              : "rgba(255, 255, 255, 0.87)"}
            secondaryTextColor={theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"}
            image={backIcon}
          />
          <MenuItem
            uniqueID={"Next"}
            position={`${menuButtonPositions.next.x} ${menuButtonPositions.next.y} ${menuButtonPositions.next.z}`}
            width="1.5"
            height="1.5"
            // text={">"}
            setSelectedID={setSelectedID}
            selectable={true}
            primaryTextColor={theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"}
            primaryPlaneColor={theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"}
            textWidth={textWidth}
            className={"menu raycastable"}
            secondaryPlaneColor={theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.87)"
              : "rgba(255, 255, 255, 0.87)"}
            secondaryTextColor={theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)"}
            image={nextIcon}
          />

          {!currentView &&
            currentItems.map(([key, value], index) => {
              const totalItems = currentItems.length;
              const columnIndex = Math.floor(index / rows);
              const rowIndex = index % rows;

              // Calculate item positions
              const xPos =
                columnIndex * (columnWidth + columnGap) - centerOffset;
              const yPos = 3
                - rowIndex * (itemHeight + itemGap);
              const imagePath = `/${thumbnails && thumbnails[key] && thumbnails[key].icon
                }`;
              const alphabetLetter = alphabetSet.split("")[index % 26];

              // Calculate total width of items for centering
              const totalWidth =
                totalItems > 0
                  ? totalItems * (columnWidth + columnGap) + columnGap
                  : 0; // Subtract columnGap for the last item
              const centerAdjustment = totalWidth / 2 - (centerOffset || 0) - 6;

              // Adjust xPos for centering based on the number of items
              const centeredXPos = xPos - centerAdjustment;
              { console.log("designer thumbnail", thumbnails) }
              return thumbnails && thumbnails[key] && thumbnails[key].icon ? (
                <MenuItem
                  key={key}
                  uniqueID={key}
                  text={value}
                  position={`${centeredXPos} ${yPos} -20`} // Use centeredXPos
                  width={calculateWidth(value)}
                  height={calculateHeight(value)}
                  setSelectedID={setSelectedID}
                  selectable={true}
                  primaryTextColor={theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"}
                  secondaryPlaneColor={theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)"}
                  secondaryTextColor={theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"}
                  primaryPlaneColor={theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)"}
                  textWidth={textWidth}
                  className={"menu raycastable"}
                  image={imagePath}
                  isUserItem={true}
                  alphabetId={alphabetLetter}
                />
              ) : (
                <MenuItem
                  key={key}
                  uniqueID={key}
                  text={value}
                  position={`${centeredXPos} ${yPos} -20`}
                  width={calculateWidth(value)}
                  height={calculateHeight(value)}
                  setSelectedID={setSelectedID}
                  selectable={true}
                  primaryTextColor={theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"}
                  secondaryPlaneColor={theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)"}
                  secondaryTextColor={theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)"}
                  primaryPlaneColor={theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(255, 255, 255, 0.87)"}
                  textWidth={textWidth}
                  className={"menu raycastable"}
                  isUserItem={true}
                  alphabetId={alphabetLetter}
                  image={
                    currentSection === "Designer"
                      ? designerIcon
                      : currentSection === "Project"
                        ? projectIcon
                        : currentSection === "Room"
                          ? roomIcon
                          : viewsIcon
                  }
                />
              );
            })}

          {Object.entries(currentItems).length == 0 && (
            <MenuItem
              text={`No items to display\nPlease ask your designer to share`}
              position="0 4 -20"
              width={"Please ask your designer to share".length * 0.5}
              height="2"
              textWidth={20}
              setSelectedID={setSelectedID}
              selectable={false}
              uniqueID={"NoItems"}
              primaryTextColor={theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)"}
              secondaryPlaneColor={theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)"}
              secondaryTextColor={theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)"}
              primaryPlaneColor={theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)"}
            />
          )}
          {!isDesktop && (
            <MenuItem
              uniqueID={"HideMenu"}
              text="Hide Menu"
              position={`${menuButtonPositions.hide.x} ${menuButtonPositions.hide.y} ${menuButtonPositions.hide.z}`}
              width="5"
              height="2"
              setSelectedID={setSelectedID}
              selectable={true}
              primaryTextColor={theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)"}
              secondaryPlaneColor={theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)"}
              secondaryTextColor={theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)"}
              primaryPlaneColor={theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)"}
              textWidth={textWidth}
              className={"menu raycastable"}
            />
          )}
        </>
      )}
    </a-entity>
  );
};

export default createMenu;
