import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const NotFoundPage = () => {
  const theme = useTheme();
  const handleBackToHome = () => {
    // Replace this with your logic to navigate back to the home page
    window.location.href = '/';
  };

  return (
    <Container
      style={{
        textAlign: 'center',
        marginTop: theme.spacing(10),
        color: theme.palette.text.primary,
      }}
    > 
      <Typography variant="h1" component="h1" style={{ color: '#36494E' }}>
        404
      </Typography>
      <Typography variant="h4" component="h2" style={{ color: '#A9CEF4' }}>
        Oops! Page not found.
      </Typography>
      <Typography variant="body1" style={{ margin: theme.spacing(4, 0), color: '#7EA0B7' }}>
        The page you are looking for might have been removed or is temporarily unavailable.
      </Typography>
      <Button
        variant="contained"
        color="primary" 
        onClick={handleBackToHome}
        style={{
          backgroundColor: '#597081',
          color: '#000000',
        }}
      >
        Back to Home
      </Button>
    </Container>
  );
};

export default NotFoundPage;
