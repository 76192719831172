import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserSessionPersistence  } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref} from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyAxip6s1_n-2fkSgRgC-VHU1kC6EIMoB4c",
  authDomain: "interia-d5652.firebaseapp.com",
  projectId: "interia-d5652",
  storageBucket: "interia-d5652.appspot.com",
  messagingSenderId: "643306694713",
  appId: "1:643306694713:web:135c5bdc4c740a602bc5e8",
  measurementId: "G-EKW1YZGM8R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const store = getFirestore(app);

export const storage = getStorage(app);
export const storageRef = ref(storage);

setPersistence(auth, browserSessionPersistence );
export default app;