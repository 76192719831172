export const userStorage = {
  users: "multivrseInteriaUsers",
  sharedProjectsCustomer: "sharedProjectsCustomer",
  sharedProjectsDesigner: "sharedProjectsDesigner",
};
export const URL = {
  authServer: "https://firebase-admin-sdk-node-server.vercel.app",
  baseURL: "gs://interia-d5652.appspot.com",
  MQTTServer:
    "wss://3c9e8ab2c2cf4d7f8711183b2b91a0c4.s1.eu.hivemq.cloud:8884/mqtt",
};

export const MQTTOptions = {
  protocol: "wss",
  username: "shoukath", // If authentication is required
  password: "Simutopia1!@#100", // If authentication is required
};

export const witAIToken = "A3FPPHVJIDBEJ47AW7MYJNJL5EKR2GPU";

export const defaults = {
  user: {
    firstname: "X",
    lastname: "YZ",
  },
};

export const thumbnailsData = [
  {
    icon: "icons/001-interior design.png",
    name: "interior design",
    category: "Design",
  },
  {
    icon: "icons/002-stairs.png",
    name: "stairs",
    category: "Architecture",
  },
  {
    icon: "icons/003-living room.png",
    name: "living room",
    category: "Furniture",
  },
  {
    icon: "icons/004-brick wall.png",
    name: "brick wall",
    category: "Structure",
  },
  {
    icon: "icons/005-mirror.png",
    name: "mirror",
    category: "Decor",
  },
  {
    icon: "icons/006-home office.png",
    name: "home office",
    category: "Furniture",
  },
  {
    icon: "icons/007-3d model.png",
    name: "3d model",
    category: "Design",
  },
  {
    icon: "icons/008-design tool.png",
    name: "design tool",
    category: "Tools",
  },
  {
    icon: "icons/009-interior design.png",
    name: "interior design",
    category: "Design",
  },
  {
    icon: "icons/010-baby room.png",
    name: "baby room",
    category: "Furniture",
  },
  {
    icon: "icons/011-fireplace.png",
    name: "fireplace",
    category: "Furniture",
  },
  {
    icon: "icons/012-house.png",
    name: "house",
    category: "Architecture",
  },
  {
    icon: "icons/013-fountain.png",
    name: "fountain",
    category: "Decor",
  },
  {
    icon: "icons/014-floor plan.png",
    name: "floor plan",
    category: "Architecture",
  },
  {
    icon: "icons/015-swimming.png",
    name: "swimming",
    category: "Recreation",
  },
  {
    icon: "icons/016-laundry room.png",
    name: "laundry room",
    category: "Utility",
  },
  {
    icon: "icons/017-curtain.png",
    name: "curtain",
    category: "Decor",
  },
  {
    icon: "icons/018-bathroom.png",
    name: "bathroom",
    category: "Utility",
  },
  {
    icon: "icons/019-front door.png",
    name: "front door",
    category: "Architecture",
  },
  {
    icon: "icons/020-car parking.png",
    name: "car parking",
    category: "Architecture",
  },
  {
    icon: "icons/021-washroom.png",
    name: "washroom",
    category: "Utility",
  },
  {
    icon: "icons/022-home cinema.png",
    name: "home cinema",
    category: "Entertainment",
  },
  {
    icon: "icons/023-window.png",
    name: "window",
    category: "Architecture",
  },
  {
    icon: "icons/024-dining room.png",
    name: "dining room",
    category: "Furniture",
  },
  {
    icon: "icons/025-kitchen.png",
    name: "kitchen",
    category: "Utility",
  },
  {
    icon: "icons/026-floor plan.png",
    name: "floor plan",
    category: "Architecture",
  },
  {
    icon: "icons/027-plant pot.png",
    name: "plant pot",
    category: "Decor",
  },
  {
    icon: "icons/028-couch.png",
    name: "couch",
    category: "Furniture",
  },
  {
    icon: "icons/029-gate.png",
    name: "gate",
    category: "Architecture",
  },
  {
    icon: "icons/030-house painting.png",
    name: "house painting",
    category: "Decor",
  },
  {
    icon: "icons/031-living room.png",
    name: "living room",
    category: "Furniture",
  },
  {
    icon: "icons/032-mirror.png",
    name: "mirror",
    category: "Decor",
  },
  {
    icon: "icons/033-baby room.png",
    name: "baby room",
    category: "Furniture",
  },
  {
    icon: "icons/034-home theater.png",
    name: "home theater",
    category: "Entertainment",
  },
  {
    icon: "icons/035-sliding door.png",
    name: "sliding door",
    category: "Architecture",
  },
  {
    icon: "icons/036-shower.png",
    name: "shower",
    category: "Utility",
  },
  {
    icon: "icons/037-window.png",
    name: "window",
    category: "Architecture",
  },
  {
    icon: "icons/038-single door.png",
    name: "single door",
    category: "Architecture",
  },
  {
    icon: "icons/039-grandfather clock.png",
    name: "grandfather clock",
    category: "Decor",
  },
  {
    icon: "icons/040-living room.png",
    name: "living room",
    category: "Furniture",
  },
  {
    icon: "icons/041-gym.png",
    name: "gym",
    category: "Recreation",
  },
  {
    icon: "icons/042-led.png",
    name: "led",
    category: "Electronics",
  },
  {
    icon: "icons/043-power switch.png",
    name: "power switch",
    category: "Electronics",
  },
  {
    icon: "icons/044-sleeping.png",
    name: "sleeping",
    category: "Utility",
  },
  {
    icon: "icons/045-central heating.png",
    name: "central heating",
    category: "Utility",
  },
  {
    icon: "icons/046-utility room.png",
    name: "utility room",
    category: "Utility",
  },
  {
    icon: "icons/047-sink.png",
    name: "sink",
    category: "Utility",
  },
  {
    icon: "icons/048-wall lamp.png",
    name: "wall lamp",
    category: "Decor",
  },
  {
    icon: "icons/049-blind.png",
    name: "blind",
    category: "Decor",
  },
  {
    icon: "icons/050-floor lamp.png",
    name: "floor lamp",
    category: "Decor",
  },
  {
    icon: "icons/051-window.png",
    name: "window",
    category: "Architecture",
  },
  {
    icon: "icons/052-sofa.png",
    name: "sofa",
    category: "Furniture",
  },
  {
    icon: "icons/053-fence.png",
    name: "fence",
    category: "Architecture",
  },
  {
    icon: "icons/054-kitchen table.png",
    name: "kitchen table",
    category: "Furniture",
  },
  {
    icon: "icons/055-fishbowl.png",
    name: "fishbowl",
    category: "Decor",
  },
  {
    icon: "icons/056-iron board.png",
    name: "iron board",
    category: "Utility",
  },
  {
    icon: "icons/057-drawer.png",
    name: "drawer",
    category: "Furniture",
  },
  {
    icon: "icons/058-couch.png",
    name: "couch",
    category: "Furniture",
  },
  {
    icon: "icons/059-washbasin.png",
    name: "washbasin",
    category: "Utility",
  },
  {
    icon: "icons/060-sofa bed.png",
    name: "sofa bed",
    category: "Furniture",
  },
  {
    icon: "icons/061-bedroom.png",
    name: "bedroom",
    category: "Furniture",
  },
  {
    icon: "icons/062-drawer.png",
    name: "drawer",
    category: "Furniture",
  },
  {
    icon: "icons/063-lighting.png",
    name: "lighting",
    category: "Electronics",
  },
  {
    icon: "icons/064-workplace.png",
    name: "workplace",
    category: "Furniture",
  },
  {
    icon: "icons/065-librarian.png",
    name: "librarian",
    category: "Person",
  },
  {
    icon: "icons/066-kitchen.png",
    name: "kitchen",
    category: "Utility",
  },
  {
    icon: "icons/067-lamp.png",
    name: "lamp",
    category: "Electronics",
  },
  {
    icon: "icons/068-table lamp.png",
    name: "table lamp",
    category: "Electronics",
  },
  {
    icon: "icons/069-table.png",
    name: "table",
    category: "Furniture",
  },
  {
    icon: "icons/070-drawer.png",
    name: "drawer",
    category: "Furniture",
  },
  {
    icon: "icons/071-towel hanger.png",
    name: "towel hanger",
    category: "Utility",
  },
  {
    icon: "icons/072-workplace.png",
    name: "workplace",
    category: "Furniture",
  },
  {
    icon: "icons/073-dressing room.png",
    name: "dressing room",
    category: "Utility",
  },
  {
    icon: "icons/074-lamp.png",
    name: "lamp",
    category: "Electronics",
  },
  {
    icon: "icons/075-chair.png",
    name: "chair",
    category: "Furniture",
  },
  {
    icon: "icons/076-drawer.png",
    name: "drawer",
    category: "Furniture",
  },
  {
    icon: "icons/077-book.png",
    name: "book",
    category: "Utility",
  },
  {
    icon: "icons/078-cupboard.png",
    name: "cupboard",
    category: "Furniture",
  },
  {
    icon: "icons/079-table.png",
    name: "table",
    category: "Furniture",
  },
  {
    icon: "icons/080-home theater.png",
    name: "home theater",
    category: "Entertainment",
  },
  {
    icon: "icons/081-floor lamp.png",
    name: "floor lamp",
    category: "Decor",
  },
  {
    icon: "icons/082-book shelf.png",
    name: "book shelf",
    category: "Furniture",
  },
  {
    icon: "icons/083-hallway.png",
    name: "hallway",
    category: "Architecture",
  },
  {
    icon: "icons/084-table lamp.png",
    name: "table lamp",
    category: "Electronics",
  },
  {
    icon: "icons/085-sofa.png",
    name: "sofa",
    category: "Furniture",
  },
  {
    icon: "icons/086-bedroom.png",
    name: "bedroom",
    category: "Furniture",
  },
  {
    icon: "icons/087-mirror.png",
    name: "mirror",
    category: "Decor",
  },
  {
    icon: "icons/088-floor lamp.png",
    name: "floor lamp",
    category: "Decor",
  },
  {
    icon: "icons/089-washbasin.png",
    name: "washbasin",
    category: "Utility",
  },
  {
    icon: "icons/090-sofa.png",
    name: "sofa",
    category: "Furniture",
  },
  {
    icon: "icons/091-bed.png",
    name: "bed",
    category: "Furniture",
  },
  {
    icon: "icons/092-bathroom.png",
    name: "bathroom",
    category: "Utility",
  },
  {
    icon: "icons/093-towel.png",
    name: "towel",
    category: "Utility",
  },
  {
    icon: "icons/094-book shelf.png",
    name: "book shelf",
    category: "Furniture",
  },
  {
    icon: "icons/095-lighting.png",
    name: "lighting",
    category: "Electronics",
  },
  {
    icon: "icons/096-cupboard.png",
    name: "cupboard",
    category: "Furniture",
  },
  {
    icon: "icons/097-office table.png",
    name: "office table",
    category: "Furniture",
  },
  {
    icon: "icons/098-mirror.png",
    name: "mirror",
    category: "Decor",
  },
  {
    icon: "icons/099-sofa.png",
    name: "sofa",
    category: "Furniture",
  },
  {
    icon: "icons/100-bunk bed.png",
    name: "bunk bed",
    category: "Furniture",
  },
  {
    name: "businessman",
    icon: "icons/001-businessman.png",
    category: "Avatar",
  },
  { name: "man", icon: "icons/002-man.png", category: "Avatar" },
  { name: "man", icon: "icons/003-man.png", category: "Avatar" },
  { name: "man", icon: "icons/004-man.png", category: "Avatar" },
  { name: "man", icon: "icons/005-man.png", category: "Avatar" },
  { name: "man", icon: "icons/006-man.png", category: "Avatar" },
  { name: "man", icon: "icons/007-man.png", category: "Avatar" },
  { name: "man", icon: "icons/008-man.png", category: "Avatar" },
  { name: "man", icon: "icons/009-man.png", category: "Avatar" },
  { name: "man", icon: "icons/010-man.png", category: "Avatar" },
  { name: "man", icon: "icons/011-man.png", category: "Avatar" },
  { name: "man", icon: "icons/012-man.png", category: "Avatar" },
  {
    name: "businessman",
    icon: "icons/013-businessman.png",
    category: "Avatar",
  },
  { name: "man", icon: "icons/014-man.png", category: "Avatar" },
  { name: "man", icon: "icons/015-man.png", category: "Avatar" },
  { name: "man", icon: "icons/016-man.png", category: "Avatar" },
  { name: "man", icon: "icons/017-man.png", category: "Avatar" },
  { name: "man", icon: "icons/018-man.png", category: "Avatar" },
  { name: "woman", icon: "icons/019-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/020-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/021-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/022-woman.png", category: "Avatar" },
  {
    name: "businessman",
    icon: "icons/023-businessman.png",
    category: "Avatar",
  },
  { name: "woman", icon: "icons/024-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/025-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/026-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/027-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/028-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/029-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/030-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/031-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/032-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/033-woman.png", category: "Avatar" },
  { name: "man", icon: "icons/034-man.png", category: "Avatar" },
  { name: "woman", icon: "icons/035-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/036-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/037-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/038-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/039-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/040-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/041-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/042-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/043-woman.png", category: "Avatar" },
  { name: "woman", icon: "icons/044-woman.png", category: "Avatar" },
  { name: "man", icon: "icons/045-man.png", category: "Avatar" },
  { name: "woman", icon: "icons/046-woman.png", category: "Avatar" },
  { name: "man", icon: "icons/047-man.png", category: "Avatar" },
  { name: "man", icon: "icons/048-man.png", category: "Avatar" },
  { name: "man", icon: "icons/049-man.png", category: "Avatar" },
  { name: "man", icon: "icons/050-man.png", category: "Avatar" },
];

export const thumbnailsCategories = [
  "All",
  "Furniture",
  "Utility",
  "Electronics",
  "Entertainment",
  "Decor",
  "Architecture",
  "Avatar",
];
