import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../authentication/Firebase"; 
import InteriaLogo from "../../icons/interia_logo.svg";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="/" style={{ fontFamily: "Audiowide" }}>
        Interia
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [darkMode, setDarkMode] = React.useState(() => localStorage.getItem("theme") === "dark");
  const navigate = useNavigate();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  React.useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        navigate("/signin");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage(`Email has been sent to ${email}. Please check your inbox.`);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Failed to send password reset email. Please check the email and try again.");
      setSuccessMessage("");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link href="/">
            <Avatar
              alt="logo of Interia"
              src={InteriaLogo}
              sx={{
                width: 150,
                height: 150,
              }}
            />
          </Link>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter your email address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
            />
            {errorMessage && (
              <Typography variant="body2" color="error" align="center" sx={{ mt: 1 }}>
                {errorMessage}
              </Typography>
            )}
            {successMessage && (
              <Typography variant="body2" color="success" align="center" sx={{ mt: 1, fontSize:"1.2rem" }}>
                {successMessage}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Reset Email
            </Button>
            <Link
              href="/signin"
              variant="body2"
              sx={{ display: "block", textAlign: "center", mt: 2 }}
            >
              {"Remembered your password? Sign In"}
            </Link>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

export default ForgotPassword;
